var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.getSidebarTitle,
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
          "hide-save-button": _vm.isReadOnly,
          "extra-buttons": _vm.sidebarExtraButtons,
        },
        on: {
          save: _vm.saveAccountPayable,
          hidden: _vm.onHideSidebar,
          print: _vm.onPrintAccountPayable,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between" },
                      [
                        _c("p", [_vm._v("Valor disponível para despesa")]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(_vm.totalOutputCashAvailable)
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "FormulateForm",
                      {
                        ref: "accountPayableFormSidebar",
                        attrs: { name: "accountPayableFormSidebar" },
                      },
                      [
                        _c("e-management-account", {
                          attrs: {
                            id: "account_payable-management-account",
                            name: "accountPayableManagementAccount",
                            required: true,
                            type: "Output",
                            "is-read-only": _vm.isReadOnly,
                          },
                          model: {
                            value: _vm.localAccountPayable.managementAccountId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localAccountPayable,
                                "managementAccountId",
                                $$v
                              )
                            },
                            expression:
                              "localAccountPayable.managementAccountId",
                          },
                        }),
                        _c("e-person-search", {
                          attrs: {
                            id: "account_payable-supplierId",
                            required: true,
                            label: _vm.$t("Fornecedor"),
                            "default-options": _vm.defaultOptions.suppliers,
                            "is-supplier": "",
                            "is-read-only": _vm.isReadOnly,
                          },
                          model: {
                            value: _vm.localAccountPayable.supplierId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localAccountPayable,
                                "supplierId",
                                $$v
                              )
                            },
                            expression: "localAccountPayable.supplierId",
                          },
                        }),
                        _c("FormulateInput", {
                          attrs: {
                            id: "account_payable-document-type",
                            label: _vm.$t("Tipo do documento"),
                            type: "vue-select",
                            options: _vm.documentTypeOptions(),
                          },
                          model: {
                            value: _vm.localAccountPayable.documentType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localAccountPayable,
                                "documentType",
                                $$v
                              )
                            },
                            expression: "localAccountPayable.documentType",
                          },
                        }),
                        _c("e-only-number-input", {
                          attrs: {
                            id: "account_payable-document",
                            label: _vm.$t("Nº do documento"),
                            required: "",
                          },
                          model: {
                            value: _vm.localAccountPayable.document,
                            callback: function ($$v) {
                              _vm.$set(_vm.localAccountPayable, "document", $$v)
                            },
                            expression: "localAccountPayable.document",
                          },
                        }),
                        _c(
                          "div",
                          [
                            _vm.isReadOnly
                              ? _c("FormulateInput", {
                                  attrs: {
                                    id: "account_payable-value",
                                    label: _vm.$t("Valor"),
                                    type: "label",
                                    filter: "currency",
                                    validation: "required",
                                  },
                                  model: {
                                    value: _vm.localAccountPayable.value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.localAccountPayable,
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression: "localAccountPayable.value",
                                  },
                                })
                              : _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "account_payable-value",
                                    label: _vm.$t("Valor"),
                                    type: "text-number",
                                    currency: "R$",
                                    precision: 2,
                                    validation: "required|min:0.01",
                                  },
                                  model: {
                                    value: _vm.localAccountPayable.value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.localAccountPayable,
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression: "localAccountPayable.value",
                                  },
                                }),
                          ],
                          1
                        ),
                        _c("e-payment-method", {
                          attrs: {
                            id: "receivable-payment-method",
                            required: true,
                            "is-read-only": _vm.isReadOnly,
                            flag: _vm.isReadOnly
                              ? "all"
                              : "payBoxAccountsPayable",
                          },
                          model: {
                            value: _vm.localAccountPayable.paymentMethodId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localAccountPayable,
                                "paymentMethodId",
                                $$v
                              )
                            },
                            expression: "localAccountPayable.paymentMethodId",
                          },
                        }),
                        _c("FormulateInput", {
                          attrs: {
                            id: "account_payable-comments",
                            label: _vm.$t("Comentário"),
                            type: _vm.isReadOnly ? "label" : "textarea",
                            rows: "2",
                          },
                          model: {
                            value: _vm.localAccountPayable.comments,
                            callback: function ($$v) {
                              _vm.$set(_vm.localAccountPayable, "comments", $$v)
                            },
                            expression: "localAccountPayable.comments",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
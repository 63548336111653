<template>
  <section class="p-2">
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <accounts-payable-filter-form
        :filters="filters"
        is-pdv
      />
    </e-filters>

    <b-card-actions
      action-refresh
      class="pb-2"
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <accounts-payable-table
        :items="accountsPayable"
        :sorting="sorting"
        :summary-fields="summaryFields"
        is-pdv
        @fetch-data="getData"
        @show-pay-modal="showPayModal"
        @update-account-payable="updateBill"
        @delete-installment="onDeleteInstallment"
        @show-detail="showReadOnlySidebar"
      />

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="accounts-payable-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <pay-installment-modal
      ref="payInstallmentModal"
      @after-confirm="({ itemList }) => onAfterPay(itemList)"
      @remove-installment="
        (item) => {
          removeInstallmentsToPay(item)
          getData()
        }
      "
    />

    <pay-box-accounts-payable-sidebar
      ref="payBoxAccountsPayableSidebar"
      @after-save="getData"
    />

    <fab
      v-if="hasBulkPayment"
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :fixed-tooltip="true"
      :actions="fabActions"
      @clean-bulk-payment="cleanBulkPayemnt"
      @confirm-bulk-payment="showBulkPayModal"
      @add-new="showCreateSidebar"
      @click="() => {}"
    />
    <fab
      v-else
      :main-tooltip="$t('Adicionar Conta')"
      @click="showCreateSidebar"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EPageSizeSelector from '@/views/components/pagination/EPageSizeSelector.vue'
import EPaginationSummary from '@/views/components/pagination/EPaginationSummary.vue'
import Fab from '@/views/components/FAB.vue'
import { mapActions, mapGetters, mapState } from 'vuex/'
import { formulateValidation, translate } from '@/mixins'
import EFilters from '@/views/components/EFilters.vue'
import PayInstallmentModal from '../../financial/accounts-payable/components/PayInstallmentModal.vue'
import AccountsPayableTable from '../../financial/accounts-payable/components/AccountsPayableTable.vue'
import AccountsPayableFilterForm from '../../financial/accounts-payable/components/form/AccountsPayableFilterForm.vue'
import PayBoxAccountsPayableSidebar from './PayBoxAccountsPayableSidebar.vue'

export default {
  components: {
    BCardActions,
    EPageSizeSelector,
    EPaginationSummary,
    Fab,
    BRow,
    BCol,
    BPagination,
    EFilters,
    PayInstallmentModal,
    AccountsPayableTable,
    AccountsPayableFilterForm,
    PayBoxAccountsPayableSidebar,
  },

  mixins: [formulateValidation, translate],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapGetters('common/managementAccounts', ['getComboManagementAccounts']),
    ...mapState('pages/pdv/payBoxAccountsPayable', [
      'filters',
      'paging',
      'sorting',
      'accountsPayable',
      'selectedInstallments',
      'summaryFields',
    ]),

    hasBulkPayment() {
      return this.selectedInstallments.length > 0
    },

    countBulkPayment() {
      return this.selectedInstallments.length
    },

    fabActions() {
      return [
        {
          name: 'clean-bulk-payment',
          icon: 'backspace',
          color: 'red',
          tooltip: `${this.$t('Limpar pagamento em massa')} (${this.countBulkPayment})`,
          hide: !this.hasBulkPayment,
        },
        {
          name: 'confirm-bulk-payment',
          icon: 'payments',
          color: 'green',
          tooltip: `${this.$t('Realizar pagamento em massa')} (${this.countBulkPayment})`,
          hide: !this.hasBulkPayment,
        },
        {
          name: 'add-new',
          icon: 'add',
          color: 'green',
          tooltip: this.$t('Adicionar Conta'),
        },
      ].filter(act => !act.hide)
    },
  },

  mounted() {
    this.getData()
    this.fetchManagementAccounts()
    this.fetchCheckingAccounts()
  },

  methods: {
    ...mapActions('app', ['fetchCheckingAccounts']),
    ...mapActions('common/managementAccounts', ['fetchManagementAccounts']),
    ...mapActions('pages/pdv/payBoxAccountsPayable', [
      'fetchPayBoxAccountsPayable',
      'deleteInstallment',
      'resetFilters',
      'setPageSize',
      'setCurrentPage',
      'addInstallmentsToPay',
      'removeInstallmentsToPay',
      'cleanInstallmentsToPay',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchPayBoxAccountsPayable()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showPayModal(row) {
      const { item } = row
      this.$refs.payInstallmentModal.showModal(false, [item.id])
    },
    showBulkPayModal() {
      const installmentIdList = this.selectedInstallments.map(i => i.id)
      this.$refs.payInstallmentModal.showModal(true, installmentIdList)
    },
    async onAfterPay(itemList) {
      await Promise.all(
        itemList.map(async item => {
          await this.removeInstallmentsToPay(item)
        })
      )
      await this.getData()
    },

    async cleanBulkPayemnt() {
      const confirmed = await this.confirm({
        text: this.$t('Os pagamentos selecionados serão desmarcados'),
      })
      if (!confirmed) return

      this.cleanInstallmentsToPay()
      this.getData()
    },

    async onRowClickToSelectPay(item) {
      if (item.paymentDate === null) {
        const checkValue = !item.checkedToPay
        // eslint-disable-next-line no-param-reassign
        item.checkedToPay = checkValue
        this.onSelectToPay(checkValue, item)
      }
    },

    async onSelectToPay(value, item) {
      try {
        if (value) {
          await this.addInstallmentsToPay(item)
        } else {
          await this.removeInstallmentsToPay(item)
        }
      } catch (error) {
        this.showWarning({ message: error.message })
        // eslint-disable-next-line no-param-reassign
        item.checkedToPay = !value
      }
    },

    showCreateSidebar() {
      this.$refs.payBoxAccountsPayableSidebar.show()
    },
    updateBill() {
      throw new Error('Atualização sem implementação')
    },
    showReadOnlySidebar(row) {
      const { item } = row
      this.$refs.payBoxAccountsPayableSidebar.showReadOnly({ id: item?.accountsPayable?.id })
    },
    async onDeleteInstallment(installmentId) {
      try {
        this.fetching = true
        await this.deleteInstallment(installmentId)
        this.showSuccess({ message: this.$t('Parcela excluída com sucesso') })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>

<style lang="scss"></style>

<template>
  <div>
    <e-sidebar
      :title="getSidebarTitle"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      :hide-save-button="isReadOnly"
      :extra-buttons="sidebarExtraButtons"
      @save="saveAccountPayable"
      @hidden="onHideSidebar"
      @print="onPrintAccountPayable"
    >
      <template #content>
        <div>
          <div class="d-flex justify-content-between">
            <p>Valor disponível para despesa</p>
            <p>{{ totalOutputCashAvailable | currency }}</p>
          </div>

          <FormulateForm
            ref="accountPayableFormSidebar"
            name="accountPayableFormSidebar"
          >
            <e-management-account
              id="account_payable-management-account"
              v-model="localAccountPayable.managementAccountId"
              name="accountPayableManagementAccount"
              :required="true"
              type="Output"
              :is-read-only="isReadOnly"
            />
            <e-person-search
              id="account_payable-supplierId"
              v-model="localAccountPayable.supplierId"
              :required="true"
              :label="$t('Fornecedor')"
              :default-options="defaultOptions.suppliers"
              is-supplier
              :is-read-only="isReadOnly"
            />
            <FormulateInput
              id="account_payable-document-type"
              v-model="localAccountPayable.documentType"
              :label="$t('Tipo do documento')"
              type="vue-select"
              :options="documentTypeOptions()"
            />
            <e-only-number-input
              id="account_payable-document"
              v-model="localAccountPayable.document"
              :label="$t('Nº do documento')"
              required
            />
            <div>
              <FormulateInput
                v-if="isReadOnly"
                id="account_payable-value"
                v-model="localAccountPayable.value"
                :label="$t('Valor')"
                type="label"
                filter="currency"
                validation="required"
              />
              <FormulateInput
                v-else
                id="account_payable-value"
                v-model="localAccountPayable.value"
                :label="$t('Valor')"
                type="text-number"
                currency="R$"
                :precision="2"
                class="required"
                validation="required|min:0.01"
              />
            </div>

            <e-payment-method
              id="receivable-payment-method"
              v-model="localAccountPayable.paymentMethodId"
              :required="true"
              :is-read-only="isReadOnly"
              :flag="isReadOnly ? 'all' : 'payBoxAccountsPayable'"
            />
            <FormulateInput
              id="account_payable-comments"
              v-model="localAccountPayable.comments"
              :label="$t('Comentário')"
              :type="isReadOnly ? 'label' : 'textarea'"
              rows="2"
            />
          </FormulateForm>
        </div>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { ESidebar, EPersonSearch, EManagementAccount } from '@/views/components'
import { financialDomain, loadingOverlay, paymentTypes, uploader } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import EPaymentMethod from '@/views/components/inputs/EPaymentMethod.vue'
import delegablePermissions from '@/utils/delegable-permissions'
import EOnlyNumberInput from '@/views/components/inputs/EOnlyNumberInput.vue'

const getInitialAccountPayable = () => ({
  id: null,
  // installmentId: null,
  payBoxId: null,
  managementAccountId: null,
  paymentMethodId: null,
  // customerId: null,
  documentType: null,
  document: null,
  supplierId: null,
  comments: null,
  value: 0,
})

const getInitialDefaultOptions = () => ({
  suppliers: [],
})

export default {
  components: {
    ESidebar,
    EManagementAccount,
    EPersonSearch,
    EPaymentMethod,
    EOnlyNumberInput,
  },

  mixins: [uploader, loadingOverlay, paymentTypes, financialDomain],

  props: {
    accountPayableId: {
      type: Number,
      default: null,
    },
    // accountPayable: {
    //   type: Object,
    //   default: null,
    // },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      isReadOnly: false,
      localAccountPayableId: null,
      localAccountPayable: getInitialAccountPayable(),
      imageLocal: [],
      defaultOptions: getInitialDefaultOptions(),
      delegateUserData: null,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['currentPayboxConfiguration']),
    ...mapState('pages/pdv', ['totalOutputCashAvailable']),

    getSidebarTitle() {
      if (this.isReadOnly) return this.$t('Visualizar Despesa')
      if (this.edit) return this.$t('Editar Despesa')
      return this.$t('Nova Despesa')
    },

    sidebarExtraButtons() {
      return [
        {
          variant: 'primary',
          icon: 'printer',
          text: this.$t('Imprimir'),
          event: 'print',
          hide: !this.isReadOnly,
        },
      ].filter(btn => !btn.hide)
    },
  },

  watch: {
    // accountPayable(val) {
    //   this.localAccountPayable = val
    //   // this.imageLocal = val?.image ? [this.getFileInstance(val.image)] : []
    // },
    accountPayableId(val) {
      this.localAccountPayableId = val
    },
    async localAccountPayableId(val) {
      if (val) {
        try {
          this.fetching = true
          const { data } = await this.$http.get(
            `/api/accounting/accounts-payable/${this.localAccountPayableId}`
          )
          const installment = data.accountsPayableInstallments[0]
          this.localAccountPayable = {
            ...data,
            payBoxId: data.payBox.id,
            paymentMethodId: installment.paymentMethod?.id?.toString(),
            supplierId: data.supplier?.id?.toString(),
            managementAccountId: data.managementAccount?.id?.toString(),
          }
          this.defaultOptions = {
            ...getInitialDefaultOptions(),
            suppliers: [data.supplier],
          }
          // this.imageLocal = localAccountPayable?.image
          //   ? [this.getFileInstance(localAccountPayable.image)]
          //   : []
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      } else {
        this.localAccountPayable = getInitialAccountPayable()
      }
    },
  },

  methods: {
    ...mapActions('pages/pdv', ['updateTotalCashInPdv']),
    ...mapActions('pages/pdv/payBoxAccountsPayable', ['printAccountPayableReceipt']),

    async showCreate() {
      try {
        this.delegateUserData = await this.modalAuthRef.auth(
          delegablePermissions.PDV_ACCOUNT_PAYABLE_CREATE
        )
        this.resetSidebar()
        this.showSidebar = true
      } catch (error) {
        this.showError({ error })
      }
    },

    async show({ id, item } = {}) {
      if (!id && !item?.id) {
        this.showCreate()
      } else {
        this.resetSidebar()
        if (id) this.localAccountPayableId = id
        else if (item) this.localAccountPayable = item
        this.edit = true
        this.showSidebar = true
      }
    },
    async showReadOnly({ id, item } = {}) {
      this.resetSidebar()
      this.isReadOnly = true
      this.showSidebar = true
      if (id) this.localAccountPayableId = id
      else if (item) this.localAccountPayable = item
    },

    onHideSidebar() {
      this.$formulate.reset('accountPayableFormSidebar')
      this.resetSidebar()
      this.showSidebar = false
    },

    resetSidebar() {
      this.localAccountPayableId = null
      this.localAccountPayable = getInitialAccountPayable()
      this.imageLocal = []
      this.edit = false
      this.isReadOnly = false
    },

    async saveAccountPayable() {
      this.$refs.accountPayableFormSidebar.showErrors()
      if (!this.$refs.accountPayableFormSidebar.isValid) {
        this.showInvalidDataMessage()
        return
      }

      if (
        this.isCashPaymentMethod(this.localAccountPayable.paymentMethodId) &&
        this.totalOutputCashAvailable < this.localAccountPayable.value
      ) {
        this.showWarning({
          message: this.$t('Valor disponível insuficiente para pagamento.'),
        })
        return
      }

      try {
        this.saving = true

        const confirm = await this.confirm({ text: this.$t('Será criado uma conta a pagar') })
        if (!confirm) return

        this.showLoadingOverlay('Salvando')

        const data = {
          ...this.localAccountPayable,
          payBoxId: this.currentPayboxConfiguration?.id,
        }

        const { data: resultData } = await this.$http({
          url: '/api/accounting/accounts-payable/pay-box',
          method: this.edit ? 'PUT' : 'POST',
          data,
          headers: {
            'delegate-token': this.delegateUserData?.token,
          },
        })

        this.updateTotalCashInPdv()
        this.showSuccess({
          message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })

        this.showLoadingOverlay('Imprimindo')

        await this.printAccountPayableReceipt({
          accountPayableId: resultData.id,
          openCashDrawer: true,
        })

        this.onHideSidebar()
        this.$emit('after-save', resultData)
      } catch (error) {
        this.showError({ error })
      } finally {
        this.hideLoadingOverlay()
        this.saving = false
      }
    },

    async onPrintAccountPayable() {
      this.saving = true
      try {
        this.showLoadingOverlay('Imprimindo')
        await this.printAccountPayableReceipt({
          accountPayableId: this.localAccountPayableId,
          openCashDrawer: false,
        })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.hideLoadingOverlay()
        this.saving = false
      }
    },
  },
}
</script>

<style></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "p-2" },
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c("accounts-payable-filter-form", {
            attrs: { filters: _vm.filters, "is-pdv": "" },
          }),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          staticClass: "pb-2",
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("accounts-payable-table", {
            attrs: {
              items: _vm.accountsPayable,
              sorting: _vm.sorting,
              "summary-fields": _vm.summaryFields,
              "is-pdv": "",
            },
            on: {
              "fetch-data": _vm.getData,
              "show-pay-modal": _vm.showPayModal,
              "update-account-payable": _vm.updateBill,
              "delete-installment": _vm.onDeleteInstallment,
              "show-detail": _vm.showReadOnlySidebar,
            },
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "accounts-payable-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("pay-installment-modal", {
        ref: "payInstallmentModal",
        on: {
          "after-confirm": function (ref) {
            var itemList = ref.itemList

            return _vm.onAfterPay(itemList)
          },
          "remove-installment": function (item) {
            _vm.removeInstallmentsToPay(item)
            _vm.getData()
          },
        },
      }),
      _c("pay-box-accounts-payable-sidebar", {
        ref: "payBoxAccountsPayableSidebar",
        on: { "after-save": _vm.getData },
      }),
      _vm.hasBulkPayment
        ? _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Ações"),
              "main-icon": "keyboard_command_key",
              "fixed-tooltip": true,
              actions: _vm.fabActions,
            },
            on: {
              "clean-bulk-payment": _vm.cleanBulkPayemnt,
              "confirm-bulk-payment": _vm.showBulkPayModal,
              "add-new": _vm.showCreateSidebar,
              click: function () {},
            },
          })
        : _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Conta") },
            on: { click: _vm.showCreateSidebar },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }